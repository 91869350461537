/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";

/* Component Imports */

import { Typography } from "@mui/material";
import { useRouter } from "next/router";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import NotificationCard from "../../../page-sections/Notifications/sub-components/NotificationCard";

/* Styled Components */

const ListContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	background: theme.palette.background.paper,
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
}));

const HeadingRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 0.5rem 0rem 1rem",
}));

const Content = ({
	notification_data,
	dispatch,
	loading,
	profile_context,
	locale,
}: {
	notification_data: any;
	dispatch: any;
	loading: boolean;
	profile_context: StateInterface["profile_context"];
	locale: string;
}) => {
	const router = useRouter();
	return (
		<React.Fragment>
			<ListContainer>
				<HeadingRow
					sx={{
						justifyContent: notification_data.length > 0 ? "flex-start" : "space-between",
					}}
				>
					<Typography variant="body1">{locale === "ar-AE" ? "الإشعارات" : "Notifications"}</Typography>

					{notification_data.length > 0 ? (
						<BeegruButton
							variant="text"
							color="primary"
							href={"/" + router.locale + "/notifications"}
						>
							{locale === "ar-AE" ? "رؤية المزيد" : locale === "kn-IN" ? "ಮತ್ತಷ್ಟು ನೋಡಿ" : "see more"}
						</BeegruButton>
					) : null}
				</HeadingRow>

				{notification_data.length > 0 ? (
					notification_data.slice(0, 5).map((notification: any, index: number) => {
						const date: Date = new Date(notification.created_at);
						return (
							<NotificationCard
								key={index}
								notification_id={notification._id}
								title={notification.title}
								description={notification.description}
								created_at={date}
								sender_details={notification.sender}
								redirect_url={notification.redirect_url}
								viewed={notification.viewed}
								loading={loading}
								navbar={true}
								dispatch={dispatch}
								profile_context={profile_context}
							/>
						);
					})
				) : (
					<Typography
						variant="body2"
						sx={{ margin: "1rem 0rem 1rem 0rem" }}
					>
						{locale === "ar-AE" ? "لا توجد إشعارات لعرضها" : "No notifications to show"}
					</Typography>
				)}
			</ListContainer>
		</React.Fragment>
	);
};

export default Content;
